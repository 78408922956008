import React, {useEffect, useRef, useState} from 'react';
import {Form, Button, Alert} from "react-bootstrap";
import {Link, useHistory, useParams} from "react-router-dom";
import styled from "styled-components";
import {useAuth} from "../../context/auth.context";
import {Centered} from "../shared/centered";
import {APP_NAME} from "../../globals";

const EmailField = styled(Form.Control)`
  margin-bottom: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

const PasswordField = styled(Form.Control)`
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const SignIn = () => {

    const { id } = useParams();

    const emailRef = useRef();
    const passwordRef = useRef();

    const {currentUser, signIn, signInWithGoogle} = useAuth();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const {push} = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        return performSignIn(() => signIn(emailRef.current.value, passwordRef.current.value));
    }

    const handleSignInWithGoogle = () => {
        return performSignIn(signInWithGoogle);
    }
    const performSignIn = async (signInMethod) => {
        try {
            setLoading(true)
            setError('');
            await signInMethod()
                .then(() => setLoading(false));
        } catch {
            setError('Error while logging in!')
        }
    }

    useEffect(() => {
        document.title = APP_NAME + " - Sign In";
        if (!loading && currentUser) {
          push(`/${id}/dispatch/`)
        }
    }, [loading, currentUser, push, id])

    return (
        <Centered>
            <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form className="mb-2" onSubmit={handleSubmit}>
                <Form.Group controlId="email" style={{margin: 0}}>
                    <EmailField ref={emailRef} type="email" placeholder="Email address" defaultValue="mh@mail.de" required autoFocus/>
                </Form.Group>
                <Form.Group controlId="password" style={{margin: 0}}>
                    <PasswordField ref={passwordRef} type="password" placeholder="Password" defaultValue="password1" required/>
                </Form.Group>
                <Button disabled={loading} variant="primary" size="lg" type="submit" block>Sign In</Button>
                <Button disabled={loading} variant="outline-primary" size="lg" onClick={handleSignInWithGoogle}
                        block>Sign In with Google</Button>
            </Form>
            <div><Link className="signin-alt" to={`/${id}/password-reset`}>Forgot password?</Link></div>
            <div><Link className="signin-alt" to={`/${id}/signup`}>I don't have an account yet!</Link></div>
        </Centered>
    )
}