import React, {useEffect, useState} from "react";
import {Page} from "../../shared/page";
import {ClientWorkBench} from "./clientWorkBench";
import {setTitle} from "../../../globals";
import {useMeta} from "../../../context/meta.context";
import {useCollection} from "../../../hooks/useFirestore";
import {Col, Container, ListGroup, Row} from "react-bootstrap";
import {ClientClass} from "./clientClass";
import {useUserData} from "../../../context/user.context";
import {ClientClassesFilter} from "./clientClassesFilter";

export const ClientClasses = () => {

    const {installation} = useMeta();

    const {userData} = useUserData();

    const {docs} = useCollection(installation, 'classes');

    const [range, setRange] = useState('Upcoming');
    const [slot, setSlot] = useState('Any time');
    const [level, setLevel] = useState('All levels');
    const [duration, setDuration] = useState('Any duration');

    useEffect(() => {
        setTitle('Classes');
    }, [])

    const setFilterCriteria = (params) => {
        const {range, slot, level, duration} = params;
        setRange(range);
        setSlot(slot);
        setLevel(level);
        setDuration(duration);
    }

    const todayDate = new Date().toJSON().substr(0, 10);
    const todayTime = new Date().toJSON().substr(11, 5);

    const applyFilter = (clazz) => {
        const isInPast = clazz.date < todayDate || (clazz.date === todayDate && clazz.time < todayTime);
        // Date range
        if (range === 'Upcoming' && isInPast) {
            return false;
        }
        if (range === 'Past' && !isInPast) {
            return false;
        }
        // Time slot
        if (slot === 'Morning' && clazz.time > '11:30') {
            return false;
        }
        if (slot === 'Midday' && (clazz.time < '12:00' || clazz.time > '13:00')) {
            return false;
        }
        if (slot === 'Afternoon' && (clazz.time < '13:00' || clazz.time > '18:00')) {
            return false;
        }
        if (slot === 'Evening' && clazz.time < '18:00') {
            return false;
        }
        // Level
        if (level !== 'All levels' && level !== clazz.level) {
            return false;
        }
        // Duration
        if (duration === '45' && clazz.duration !== '45') {
            return false;
        }
        if (duration === '60' && clazz.duration !== '60') {
            return false;
        }
        if (duration === '90' && clazz.duration !== '90') {
            return false;
        }
        return true;
    }

    const filteredDocs = docs.filter(applyFilter)

    const content = () => {
        return (
            (!userData)
                ? <div>Loading ...</div>
                : docs &&
                <React.Fragment>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={12} className="text-left">
                            <ClientClassesFilter setFilterCriteria={setFilterCriteria}/>
                        </Col>
                    </Row>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={12} className="text-center">
                            {(filteredDocs.length > 0)
                                ? <ListGroup>
                                    {
                                        filteredDocs.map((clazz, idx) =>
                                            <ClientClass key={clazz.id} clazz={clazz}/>)
                                    }
                                </ListGroup>
                                : <h5 className="mt-4">No classes found. Please adjust filter criteria.</h5>
                            }
                        </Col>
                    </Row>
                </React.Fragment>
        )
    }

    return (
        <Page>
            <ClientWorkBench activeKey="classes">
                <Container className="border border-top-0 rounded-bottom">
                    <Row className="justify-content-center">
                        <Col xs={12} className="w-95 mb-4">
                            {content()}
                        </Col>
                    </Row>
                </Container>
            </ClientWorkBench>
        </Page>
    )

}