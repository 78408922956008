import React from "react";
import {Image} from "react-bootstrap";
import {useMeta} from "../../context/meta.context";

export const Logo = () => {
    const {installation} = useMeta();

    return (
        <Image className="mb-4"
               src={`/${installation}/logo.png`} alt="Logo"
               width="50"
               />
    )
}