import React from "react";
import {useAuth} from "./context/auth.context";
import {useUserData} from "./context/user.context";
import {Redirect, useParams} from "react-router-dom";

export const Dispatch = () => {

    const {id} = useParams();
    const {currentUser} = useAuth();
    const {userData} = useUserData();

    return (
        <>
            {(currentUser)
                ? (userData)
                    ? (userData.isAdmin)
                        ? <Redirect to={`/${id}/admin/classes`}/>
                        : <Redirect to={`/${id}/client/classes`}/>
                    : <div>Loading</div>
                : <Redirect to={`/${id}/signin`}/>
            }
        </>
    );

}