import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Logo} from "./logo";
import {Copyright} from "./copyright";

export const Centered = ({children}) => {
    return (
        <Container className="h-100">
            <Row className="justify-content-center h-100">
                <Col xs={12} className="my-auto text-center w-330">
                    <Logo />
                    {children}
                    <Copyright />
                </Col>
            </Row>
        </Container>
    )
}