import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {bookClass, cancelClass} from "../../../hooks/useFirestore";
import {useMeta} from "../../../context/meta.context";
import * as Icon from "react-bootstrap-icons";
import {useUserData} from "../../../context/user.context";
import {useHistory} from "react-router-dom";
import {useToast} from "../../../context/toast.context";

const MODE_SET = 1;
const MODE_CONFIRMING = 2;
const MODE_PAST = 3;

export const ClientClassControl = ({clazz}) => {

    const {installation} = useMeta();
    const {userData} = useUserData();
    const {setMessage, setError} = useToast();

    const {push} = useHistory();

    const isBooked = () => {
        return clazz.clients && clazz.clients.filter(c => c.uid === userData.uid && c.status === "Booked").length > 0;
    }


    const todayDate = new Date().toJSON().substr(0, 10);
    const todayTime = new Date().toJSON().substr(11, 5);

    const isInPast = clazz.date < todayDate || (clazz.date === todayDate && clazz.time < todayTime);

    const [mode, setMode] = useState(isInPast ? MODE_PAST : MODE_SET);

    const confirmBooking = () => {
        setMode(MODE_CONFIRMING);
    }

    const confirmCancel = () => {
        setMode(MODE_CONFIRMING);
    }

    const cancelConfirmBooking = () => {
        setMode(MODE_SET);
    }

    const keepBooking = () => {
        setMode(MODE_SET);
    }

    const makeBooking = () => {
        bookClass(installation, 'classes', clazz, userData.uid)
            .then(() => setMode(MODE_SET))
            .then(() => setMessage('Thank you for booking this class!'))
            .catch((e) => {
                setMode(MODE_SET);
                setError(e.message)
            })
    }

    const deleteBooking = () => {
        cancelClass(installation, 'classes', clazz, userData.uid)
            .then(() => setMode(MODE_SET))
            .then(() => setMessage('You have cancelled this class!'))
            .catch((e) => {
                setMode(MODE_SET);
                setError(e.message)
            })
    }

    const joinClass = () => {

    }

    const hasSufficientCredit = () => {
        return parseFloat(userData.credit) >= parseFloat(clazz.price);
    }

    const goToAccountPage = () => {
        push(`/${installation}/client/account/`)
    }

    const controls = () => {
        switch (mode) {
            case MODE_CONFIRMING:
                return (isBooked()
                        ? <>
                            <Button variant="outline-secondary" className="mr-3" onClick={keepBooking}>Keep</Button>
                            <Button autoFocus variant="danger" onClick={deleteBooking}>Cancel booking</Button>
                        </>
                        : <>
                            <Button variant="outline-secondary" className="mr-3"
                                    onClick={cancelConfirmBooking}>Cancel</Button>
                            <Button autoFocus variant="success" onClick={makeBooking}>Yes, book!</Button>
                        </>
                );
            case MODE_PAST:
                return (isBooked()
                        ? <>
                            <Button disabled variant="outline-secondary" className="mr-3" onClick={joinClass}>Join now</Button>
                            <Button disabled variant="outline-secondary"
                                    onClick={confirmCancel}>
                                <Icon.Trash size={15}/>
                            </Button>
                        </>
                        : <Button disabled variant="outline-secondary" onClick={goToAccountPage}>Book!</Button>
                );
            case MODE_SET:
            default:
                return (isBooked()
                        ? <>
                            <Button variant="outline-success" className="mr-3" onClick={joinClass}>Join now</Button>
                            <Button variant="outline-danger"
                                    onClick={confirmCancel}>
                                <Icon.Trash size={15}/>
                            </Button>
                        </>
                        : <>
                            {(hasSufficientCredit())
                                ? <Button variant="outline-primary" onClick={confirmBooking}>Book!</Button>
                                :
                                <Button variant="outline-primary" onClick={goToAccountPage}>Add credit to book</Button>
                            }
                        </>
                );
        }
    }

    return (
        <React.Fragment>
            <Row className="w-100 h-100 m-0 p-0 align-items-center justify-content-center">
                <Col xs={12} className="m-0 pr-3 text-right">
                    {controls()}
                </Col>
            </Row>
        </React.Fragment>
    );
}