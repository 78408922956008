import React from "react";
import {Col, ListGroup, Row} from "react-bootstrap";
import {CurrencyValue} from "../../shared/currencyValue";
import * as Icon from "react-bootstrap-icons";
import {ClientClassControl} from "./clientClassControl";

export const ClientClass = ({clazz}) => {

    return (
        <ListGroup.Item as="li" action className="m-0 p-2 pl-3 pr-3">
            <Row className="justify-content-center">
                <Col xs={12} md={7} lg={8} className="">
                    <Row>
                        <Col xs={6} md={4} lg={3} xl={2} className= "text-left text-muted">
                            {clazz.date}
                        </Col>
                        <Col xs={6} md={4} lg={4} xl={2} className="text-left text-muted">
                            {clazz.time}
                        </Col>
                        <Col xs={6} md={4} lg={5} xl={8} className="text-left text-muted">
                            {clazz.duration} min
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-left align-items-center">
                            <div className="font-weight-bold fs-larger text-left">
                            <Icon.Flower1 size={25} style={{
                                paddingRight: 10,
                                marginBottom: 3
                            }}/>
                            {clazz.title}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} lg={2} className="text-left text-muted">
                            {clazz.level}
                        </Col>
                        <Col xs={6} md={8} lg={10} className="text-left text-muted">
                            <CurrencyValue amount={clazz.price} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={5} lg={4} className="m-0 pl-3 pr-0 w-100 align-items-center text-right">
                    <ClientClassControl clazz={clazz}/>
                </Col>
            </Row>
        </ListGroup.Item>
    )

}