import React from "react";
import {Col, ListGroup, Row} from "react-bootstrap";
import {CurrencyValue} from "../../shared/currencyValue";
import {Link} from "react-router-dom";

export const AdminClient = ({client}) => {

    return (
        <ListGroup.Item as="li" action>
            <Row>
                <Col className="border-danger">
                    <Row>
                        <Col sm={12} md={6} className="text-left">
                            <Link to={`detail/?id=${client.uid}`}>{client.displayName}</Link>
                        </Col>
                        <Col sm={12} md={6}>
                            {client.email}
                        </Col>
                    </Row>
                </Col>
                <Col xs={5} md={3} className="text-right">
                    Credit: <CurrencyValue amount={client.credit}/>
                </Col>
            </Row>
        </ListGroup.Item>
    )

}