import React, {useContext, useEffect, useState} from "react";
import {
    auth,
    createUserDocument,
    googleAuthProvider,
    updateUserDocument
} from '../firebase/firebase'

const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({installation, children, ...rest}) => {


    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const signUp = (displayName, email, password) => {
        return auth.createUserWithEmailAndPassword(email, password)
            .then((res) =>
                createUserDocument(installation, res.user, {
                    displayName,
                    isAdmin: false,
                    isManaged: false
                }));
    }

    const signIn = (email, password) => {
        return auth.signInWithEmailAndPassword(email, password);
    }

    const signInWithGoogle = () => {
        return auth.signInWithPopup(googleAuthProvider)
            .then((res) =>
                createUserDocument(installation, res.user, {
                    isAdmin: false,
                    isManaged: true
                }));
    }

    const signOut = () => {
        return auth.signOut().catch((e) => console.log('Error: ', e.message));
    }

    const resetPassword = (email) => {
        return auth.sendPasswordResetEmail(email);
    }

    const updateEmailAndDisplayName = (email, displayName) => {
        return auth.currentUser.updateEmail(email)
            .then(() => updateUserDocument(installation, auth.currentUser, {email, displayName}));
    }

    const updatePassword = (newPassword) => {
        return auth.currentUser.updatePassword(newPassword);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            setLoading(false);
        })

        return unsubscribe
    }, [installation]);

    const value = {
        installation,
        currentUser,
        signUp,
        signIn,
        signInWithGoogle,
        signOut,
        resetPassword,
        updateEmailAndDisplayName,
        updatePassword
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}