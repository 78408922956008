import React from "react";
import {Centered} from "./components/shared/centered";

export const UnderMaintenance = ({message}) => {

    return (
        <Centered>
            <h2 className="mb-3 fw-normal">Under maintenance.</h2>
            <h4>{message}</h4>
        </Centered>
    )
}