import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore";

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
})

export default app
export const auth = app.auth()
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const firestore = app.firestore();

export const createUserDocument = (installation, user, additionalData) => {
    if (!user) {
        return null;
    }

    return firestore.runTransaction((transaction) => {
        const userRef = firestore.doc(`installations/${installation}/users/${user.uid}`);
        return transaction.get(userRef)
            .then((snapshot) => {
                if (!snapshot.exists) {
                    const {email, displayName} = user;
                    return transaction.set(userRef, {
                        uid: user.uid,
                        displayName,
                        email,
                        ...additionalData
                    });
                }
            });
    })
        .then(() => {
            return getUserDocument(user.uid)
        });
};

export const getUserDocument = async (installation, user) => {
    if (!user) return null;
    const userDocument = await firestore.doc(`installations/${installation}/users/${user.uid}`).get();
    return {
        ...user,
        ...userDocument.data()
    };
}

export const updateUserDocument = async (installation, user, newData) => {
    if (!user) {
        return null;
    }

    return firestore.runTransaction((transaction) => {
        const userRef = firestore.doc(`installations/${installation}/users/${user.uid}`);
        return transaction.get(userRef)
            .then((snapshot) => {
                if (snapshot) {
                    return transaction.set(userRef, {
                        ...snapshot.data(),
                        ...newData
                    });
                }
            })
    })
        .then(() => {
            return getUserDocument(installation, user);
        });
}
