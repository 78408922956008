import React from "react";
import {Col, ListGroup, Row} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import {CurrencyValue} from "../../shared/currencyValue";

export const AdminClientDetailClass = ({clazz, clientId}) => {

    const status = (clientId) => {
        return clazz.clients.filter(c => c.uid === clientId)[0].status;
    }

    const isBooked = (clientId) => {
        return status(clientId) === 'Booked';
    }

    return (
        <ListGroup.Item as="li" action className="m-0 p-2 pl-3 pr-3">
            <Row>
                <Col xs={6} md={4} lg={3} xl={2} className="text-left text-muted">
                    {clazz.date}
                </Col>
                <Col xs={6} md={4} lg={4} xl={2} className="text-left text-muted">
                    {clazz.time}
                </Col>
                <Col xs={6} md={4} lg={5} xl={8} className="text-left text-muted">
                    {clazz.duration} min
                </Col>
            </Row>
            <Row>
                <Col xs={10} className="text-left align-items-center">
                    <div className="font-weight-bold fs-larger text-left">
                        <Icon.Flower1 size={25} style={{
                            paddingRight: 10,
                            marginBottom: 3
                        }}/>
                        {clazz.title}</div>
                </Col>
                <Col xs={2} className="text-right align-items-center">
                    {(isBooked(clientId))
                        ? <Icon.CheckSquareFill size={24}/>
                        : <Icon.XSquare size={24}/>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4} lg={3} xl={2} className="text-left text-muted">
                    {clazz.level}
                </Col>
                <Col xs={6} md={4} lg={4} xl={2} className="text-left text-muted">
                    <CurrencyValue amount={clazz.price}/>
                </Col>
            </Row>
        </ListGroup.Item>
    )

}