import React from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";

export const AdminWorkBench = ({activeKey, children}) => {

    const { id } = useParams();

    const history = useHistory();

    const handleTabChange = (key) => {
       switch (key) {
           case 'clients':
               history.push(`/${id}/admin/clients/`);
               return;
           default:
               history.push(`/${id}/admin/classes/`);
       }
    }

    return (
        <Tabs id="admin-view"
              activeKey={activeKey}
              onSelect={(k) => handleTabChange(k)}
        >
            <Tab eventKey="classes" title="Classes">
                {activeKey === 'classes' && children}
            </Tab>
            <Tab eventKey="clients" title="Clients">
                {activeKey === 'clients' && children}
            </Tab>
        </Tabs>
    )

}
