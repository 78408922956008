import React, {useEffect, useRef, useState} from 'react';
import {Form, Button, Alert} from "react-bootstrap";
import {useAuth} from '../../context/auth.context'
import {Link, useHistory, useParams} from "react-router-dom";
import styled from "styled-components";
import {Centered} from "../shared/centered";
import {APP_NAME} from "../../globals";

const DisplayNameField = styled(Form.Control)`
  margin-bottom: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

const EmailField = styled(Form.Control)`
  margin-bottom: 3px;
  border-radius: 0;
`;

const PasswordField = styled(Form.Control)`
  margin-bottom: 3px;
  border-radius: 0;
`;

const ConfirmPasswordField = styled(Form.Control)`
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const SignUp = () => {
    const { id } = useParams();

    const displayNameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const {currentUser, signUp, signInWithGoogle} = useAuth();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const {push} = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match!');
        }

        try {
            setLoading(true)
            setError('');
            await signUp(displayNameRef.current.value, emailRef.current.value, passwordRef.current.value);
        } catch {
            setError('Error while signing up!')
        } finally {
            setLoading(false)
        }
    }

    const handleSignInWithGoogle = async () => {
        try {
            setLoading(true)
            setError('');
            await signInWithGoogle();
        } catch {
            setError('Error while logging in!')
        }
        setLoading(false)
    }

    useEffect(() => {
        document.title = APP_NAME + " - Sign In";
        if (!loading && currentUser) {
            push(`/${id}/dispatch/`)
        }
    }, [loading, currentUser, push, id])

    return (
        <Centered>
            <h1 className="h3 mb-3 fw-normal">Please sign up</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form className="mb-2" onSubmit={handleSubmit}>
                <Form.Group controlId="displayName" style={{margin: 0}}>
                    <DisplayNameField ref={displayNameRef} type="text" placeholder="Display name" required autoFocus/>
                </Form.Group>
                <Form.Group controlId="email" style={{margin: 0}}>
                    <EmailField ref={emailRef} type="email" placeholder="Email address" required/>
                </Form.Group>
                <Form.Group controlId="password" style={{margin: 0}}>
                    <PasswordField ref={passwordRef} type="password" placeholder="Password" required/>
                </Form.Group>
                <Form.Group controlId="passwordConfirmed" style={{margin: 0}}>
                    <ConfirmPasswordField ref={passwordConfirmRef} type="password"
                                          placeholder="Confirm Password" required/>
                </Form.Group>
                <Button disabled={loading} variant="primary" size="lg" block type="submit">Sign Up</Button>
                <Button disabled={loading} variant="outline-primary" size="lg" onClick={handleSignInWithGoogle}
                        block>Sign In with Google instead</Button>
            </Form>
            <div><Link className="signin-alt" to={`/${id}/signin/`}>I already have an account!</Link></div>
        </Centered>
    )
}