import React from "react";
import {useAuth} from "../context/auth.context";
import {Redirect, Route} from "react-router-dom";

export const PrivateRoute = ({component: Component, ...rest}) => {
    const {currentUser} = useAuth();

    const {computedMatch} = rest;
    const id = computedMatch.params.id;

    return (
        <Route
            {...rest}
            render={props => {
                return (currentUser ? <Component {...props} /> : <Redirect to={`/${id}/signin`}/>)
            }}
        >
        </Route>

    )
}


