import React from "react";
import {Redirect, Route} from "react-router-dom";
import {useUserData} from "../context/user.context";
import {useAuth} from "../context/auth.context";

export const AdminRoute = ({component: Component, ...rest}) => {
    const {currentUser} = useAuth();
    const {userData} = useUserData();

    const {computedMatch} = rest;
    const id = computedMatch.params.id;

    return (
        <Route
            {...rest}
            render={props => {
                return ((currentUser && userData && userData.isAdmin) ? <Component {...props} /> : <Redirect to={`/${id}/signin`}/>)
            }}
        >
        </Route>

    )
}


