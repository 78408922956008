import React, {useContext, useState} from "react";
import {Toast} from "../components/shared/toast";

const ToastContext = React.createContext();

export const useToast = () => {
    return useContext(ToastContext);
}

export const ToastProvider = ({children}) => {

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const value = {
        setMessage,
        setError
    }

    return (
        <ToastContext.Provider value={value}>
            { message && <Toast heading="Message" message={message} onClose={() => setMessage('')}/> }
            { error && <Toast heading="Error" message={error} onClose={() => setError('')} isError /> }
            {children}
        </ToastContext.Provider>
    );
}