import React, {useContext, useEffect, useState} from "react";
import {firestore} from "../firebase/firebase";
import {useAuth} from "./auth.context";

const UserContext = React.createContext();

export const useUserData = () => {
    return useContext(UserContext);
}

export const UserProvider = ({installation, children}) => {

    const {currentUser} = useAuth();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (currentUser) {
            const unsubscribe = firestore.doc(`installations/${installation}/users/${currentUser.uid}`)
                .onSnapshot((snapshot) => {
                    if (snapshot.exists) {
                        setUserData({
                            uid: currentUser.uid,
                            ...snapshot.data()
                        })
                    }
                })
            ;
            return unsubscribe;
        }
    }, [installation, currentUser]);

    const value = {
        userData
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}