import React, {useEffect, useRef, useState} from "react";
import {ClientWorkBench} from "./clientWorkBench";
import {Page} from "../../shared/page";
import {setTitle} from "../../../globals";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {CurrencyValue} from "../../shared/currencyValue";
import {useHistory, useParams} from "react-router-dom";
import {useAuth} from "../../../context/auth.context";
import {useUserData} from "../../../context/user.context";
import {Toast} from "../../shared/toast";

export const ClientAccount = () => {

    const {id} = useParams();

    const {push} = useHistory();

    const {userData} = useUserData();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const [topUpChanged, setTopUpChanged] = useState(false);

    const [displayNameChanged, setDisplayNameChanged] = useState(false);
    const [emailChanged, setEmailChanged] = useState(false);

    const [pwChanged, setPwChanged] = useState(false);
    const [pwMatch, setPwMatch] = useState(false);

    const topUpRef = useRef();
    const displayNameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const {updateEmailAndDisplayName, updatePassword} = useAuth();

    useEffect(() => {
        setTitle('Account');
    }, [])


    const handleTopUpChanged = () => {
        setTopUpChanged(topUpRef.current.value > 0);
    }

    const handleTopUp = (amount) => {
        goToTopUpPage(amount)
    }

    const handleCustomTopUp = (e) => {
        e.preventDefault();
        goToTopUpPage(topUpRef.current.value)
    }

    const goToTopUpPage = (amount) => {
        push(`/${id}/client/account/topup/?amount=${amount}`)
    }

    const handleDisplayNameChanged = (e) => {
        setDisplayNameChanged(userData.displayName !== displayNameRef.current.value);
    }

    const handleEmailChanged = (e) => {
        const valid = emailRef.current.value.length > 3 && emailRef.current.value.includes('@');
        setEmailChanged(userData.email !== emailRef.current.value && valid);
    }

    const handlePwChanged = (e) => {
        setPwChanged(passwordRef.current.value.length > 0);
        setPwMatch(passwordRef.current.value === passwordConfirmRef.current.value);
    }

    const handleNameAndEmailChange = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log('handleNameAndEmailChange');

        setError('');

        console.log('Updating email ...')
        updateEmailAndDisplayName(emailRef.current.value, displayNameRef.current.value)
            .then(() => setMessage('Display name and email updated'))
            .then(() => {
                setLoading(false);
                setEmailChanged(false);
                setDisplayNameChanged(false);
            })
            .catch((err) => setError(err.message));
    }

    const handleSaveNewPassword = async (e) => {
        console.log('handleSaveNewPassword ...');
        e.preventDefault();

        setError('');
        setLoading(true);

        await updatePassword(passwordRef.current.value)
            .then(() => setMessage('Password updated'))
            .catch(e => setError(e.message));

        passwordRef.current.value = '';
        passwordConfirmRef.current.value = '';

        setLoading(false);
        setPwChanged(false);
    }

    const managedUserMessage = () => {
        return (<Alert variant="warning" className="mt-4">
            You are signed in with an externally managed account, i.e. Google etc.: <span
            className="font-weight-bold">{userData.email}</span><br/>
            Please, manage account details like display name, email address and password there. Thank you.
        </Alert>)
    }

    const topUpSection = () => {
        return (
            <Form className="mb-2 ml-0 mr-2" onSubmit={handleCustomTopUp}>
                <Row>
                    <Col xs={12} className="mt-4 w-95">
                        <h3>You are <CurrencyValue amount={userData.credit}/> in credit.</h3>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={6} md={4} className="text-right">
                        <h5>Click to add</h5>
                    </Col>
                    <Col xs={6} md={8} className="text-left">
                        <Button key="1" variant="outline-primary" className="m-2" onClick={() => handleTopUp(5)}>£5</Button>
                        <Button key="2" variant="outline-primary" className="m-2" onClick={() => handleTopUp(10)}>£10</Button>
                        <Button key="3" variant="outline-primary" className="m-2" onClick={() => handleTopUp(25)}>£25</Button>
                        <Button key="4" variant="outline-primary" className="m-2" onClick={() => handleTopUp(50)}>£50</Button>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={6} md={4} className="text-right">
                        <h5>Custom amount</h5>
                    </Col>
                    <Col xs={6} md={8} className="text-left">
                        <Form.Control ref={topUpRef} type="number" placeholder="Amount"
                                      defaultValue=''
                                      onChange={handleTopUpChanged}/>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline justify-content-right">
                    <Col xs={6} md={8} className="text-right">
                    </Col>
                    <Col xs={6} md={4} className="text-right">
                        <Button
                            disabled={loading || !topUpChanged}
                            variant="outline-primary" type="submit" block>Add credit</Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    const displayNameAndEmailSection = () => {
        return (
            <Form className="mb-2 ml-0 mr-2" onSubmit={handleNameAndEmailChange}>
                <Row>
                    <Col xs={12} className="w-95">
                        <h3 className="mt-3 text-center">Manage your name and email</h3>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={6} md={4} className="text-right">
                        <h5>Display name</h5>
                    </Col>
                    <Col xs={6} md={8}>
                        <Form.Control ref={displayNameRef} type="text" placeholder="Display name"
                                      defaultValue={userData.displayName}
                                      onChange={handleDisplayNameChanged}
                                      required/>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={6} md={4} className="text-right">
                        <h5>Email</h5>
                    </Col>
                    <Col xs={6} md={8}>
                        <Form.Control ref={emailRef} type="text" placeholder="Email"
                                      defaultValue={userData.email}
                                      onChange={handleEmailChanged}
                                      required/>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline justify-content-right">
                    <Col xs={6} md={8} className="text-right">
                    </Col>
                    <Col xs={6} md={4} className="text-right">
                        <Button
                            disabled={loading || !(displayNameChanged || emailChanged)}
                            variant="outline-primary" type="submit" block>Update</Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    const passwordSection = () => {
        return (
            <Form className="mt-4 mb-2 ml-0 mr-2" onSubmit={handleSaveNewPassword}>
                <Row>
                    <Col xs={12} className="w-95">
                        <h3 className="mt-3 text-center">Set a new password</h3>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={6} md={4} className="text-right">
                        <h5>New password</h5>
                    </Col>
                    <Col xs={6} md={8}>
                        <Form.Control ref={passwordRef}
                                      onChange={handlePwChanged}
                                      type="password" placeholder="" required/>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={6} md={4} className="text-right">
                        <h5>Confirm password</h5>
                    </Col>
                    <Col xs={6} md={8}>
                        <Form.Control ref={passwordConfirmRef}
                                      onChange={handlePwChanged}
                                      type="password" placeholder="" required/>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline justify-content-right">
                    <Col xs={6} md={8} className="text-right">
                    </Col>
                    <Col xs={6} md={4} className="text-right">
                        <Button disabled={loading || !pwChanged || !pwMatch} variant="outline-primary" type="submit" block>Change
                            password</Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    const accountMain = () => {
        return (
            <>
                {displayNameAndEmailSection()}
                {passwordSection()}
            </>
        );
    }

    const content = () => {
        return (
            <React.Fragment>
                {topUpSection()}
                {(userData.isManaged)
                    ? managedUserMessage()
                    : accountMain()
                }
            </React.Fragment>
        );
    }

    return (
        <Page>
            { message && <Toast heading="Message" message={message} onClose={() => setMessage('')}/> }
            { error && <Toast heading="Error" message={error} onClose={() => setError('')} isError /> }
            <ClientWorkBench activeKey="account">
                <Container className="border border-top-0 rounded-bottom">
                    {(!userData)
                        ? <div>Loading ...</div>
                        : content()
                    }
                </Container>
            </ClientWorkBench>
        </Page>
    )

}