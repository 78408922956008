import React, {useEffect} from "react";
import {Page} from "../../shared/page";
import {AdminWorkBench} from "./adminWorkBench";
import {setTitle} from "../../../globals";
import {useMeta} from "../../../context/meta.context";
import {useClients} from "../../../hooks/useFirestore";
import {Col, Container, ListGroup, Row} from "react-bootstrap";
import {AdminClient} from "./adminClient";

export const AdminClients = () => {

    const {installation} = useMeta();

    const {clients} = useClients(installation);

    useEffect(() => {
        setTitle('Clients');
    }, [])

    const content = () => {
        return (
            (!clients)
                ? <div>Loading ...</div>
                : <React.Fragment>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={12} className="text-left">
                            <h3>Clients</h3>
                        </Col>
                    </Row>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={12} className="text-left">
                            <ListGroup>
                                {clients.map(c => <AdminClient key={c.uid} client={c} />)}
                            </ListGroup>
                        </Col>
                    </Row>
                </React.Fragment>
        )
    }

    return (
        <Page>
            <AdminWorkBench activeKey="clients">
                <Container className="border border-top-0 rounded-bottom">
                    <Row className="justify-content-center">
                        <Col xs={12} className="w-95 mb-4">
                            {content()}
                        </Col>
                    </Row>
                </Container>
            </AdminWorkBench>
        </Page>
    )

}