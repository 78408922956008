import React, {useEffect, useState} from 'react';
import {Toast} from "../../shared/toast";
import {ClientWorkBench} from "./clientWorkBench";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Page} from "../../shared/page";
import {useUserData} from "../../../context/user.context";
import {setTitle} from "../../../globals";
import {useLocation, useHistory, useParams} from "react-router-dom";
import {topUp} from "../../../hooks/useFirestore";
import {CurrencyValue} from "../../shared/currencyValue";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const ClientAccountTopUp = () => {

    const {id} = useParams();

    const amount = useQuery().get('amount');
    const history = useHistory();

    const {userData} = useUserData();

    const [paid, setPaid] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setTitle('Top up');
    }, [])

    const handlePayment = (e) => {
        e.preventDefault();
        topUp(id, userData.uid, amount)
            .then(() => setMessage('Thank you for adding credit to your account.'))
            .then(() => setPaid(true))
            .catch((e) => setError(e.message));
    }


    const content = () => {
        return (
            <>
                {
                    paid
                        ? <>
                            <Row>
                                <Col xs={12} className="mt-4 w-95">
                                    <h3>Your account is now <CurrencyValue amount={userData.credit}/> in credit. Thank you!
                                    </h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="mt-4 w-95">
                                    <Button variant="outline-primary"
                                            className="mb-4"
                                            onClick={() => history.push(`/${id}/client/account/`)}
                                    >Back to account details</Button>
                                </Col>
                            </Row>
                        </>
                        : <Form className="mb-2 ml-0 mr-2" onSubmit={handlePayment}>
                            <Row>
                                <Col xs={12} className="mt-4 w-95">
                                    <h3>Paying {amount} ...</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="mt-4 w-95 text-center">
                                    <Button variant="secondary" className="mx-1" onClick={history.goBack}>Cancel</Button>
                                    <Button variant="primary" className="mx-1" type="submit">Pay</Button>
                                </Col>
                            </Row>
                        </Form>
                }
            </>
        );
    }

    return (
        <Page>
            {message && <Toast heading="Message" message={message} onClose={() => setMessage('')}/>}
            {error && <Toast heading="Error" message={error} onClose={() => setError('')} isError/>}
            {!userData
                ? <div>Loading ...</div>
                : <ClientWorkBench activeKey="account">
                    <Container className="border border-top-0 rounded-bottom">
                        {(!userData)
                            ? <div>Loading ...</div>
                            : content()
                        }
                    </Container>
                </ClientWorkBench>
            }
        </Page>
    )
}