import React, {useState} from "react";
import {Toast as BootstrapToast} from "react-bootstrap";

export const Toast = ({heading, message, onClose, isError}) => {

    const [visible, setVisible] = useState(true);

    const close = () => {
        setVisible(false);
        onClose();
    }

    return (

        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                position: 'fixed',
                top: '100%',
                left: '50%',
                transform: "translate(-50%, -100%)",
                minHeight: '100px',
                zIndex: 99
            }}
        >
            <BootstrapToast show={visible} onClose={close} delay={isError ? 5000 : 3000} autohide>
                <BootstrapToast.Header style={{background: (isError) ? 'salmon' : ''}}>
                    <strong className="mr-auto">{heading}</strong>
                    <small>now</small>
                </BootstrapToast.Header>
                <BootstrapToast.Body>{message}</BootstrapToast.Body>
            </BootstrapToast>
        </div>
    );
}