import React from "react";
import {useMeta} from "../../context/meta.context";

export const CurrencyValue = ({amount}) => {

    const {currencySymbol, decimalDelimiter} = useMeta();

    const numeric = parseFloat((amount) ? amount : '0.0');
    const amountAsString = numeric.toFixed(2).replace('.', (decimalDelimiter ? decimalDelimiter : '.'));

    return (
        <>
            {currencySymbol ? currencySymbol : '?'}{amountAsString}
        </>
    );
}