import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {SignIn} from "./components/signin";
import {NotFound} from "./components/notfound";

import {SignUp} from "./components/signup";
import {PasswordReset} from "./components/passwordReset";

import './App.css';
import {AuthProvider} from "./context/auth.context";
import {MetaProvider} from "./context/meta.context";
import {PrivateRoute} from "./components/private.route";
import {ToastProvider} from "./context/toast.context";
import Main from "./Main";
import {MaintenanceGuard} from "./MaintenanceGuard";

function App() {

    const installation = window.location.pathname.split('/').filter(e => e !== '')[0];

    if (installation !== 'lotusmoonlight') {
        return <NotFound/>
    }

    return (
        <BrowserRouter>
            <ToastProvider>
                <AuthProvider installation={installation}>
                    <MetaProvider installation={installation}>
                        <MaintenanceGuard>
                            <Switch>
                                <PrivateRoute path={["/:id/dispatch/", "/:id/admin/", "/:id/client/"]} component={Main}/>
                                <Route path={["/:id/", "/:id/signin"]} exact component={SignIn}/>
                                <Route path="/:id/signup" exact component={SignUp}/>
                                <Route path="/:id/password-reset" exact component={PasswordReset}/>
                            </Switch>
                        </MaintenanceGuard>
                    </MetaProvider>
                </AuthProvider>
            </ToastProvider>
        </BrowserRouter>
    );
}

export default App;
