import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {deleteDocument} from "../../../hooks/useFirestore";
import {useMeta} from "../../../context/meta.context";
import * as Icon from "react-bootstrap-icons";
import {useHistory} from "react-router-dom";
import {useToast} from "../../../context/toast.context";

const MODE_SET = 1;
const MODE_CONFIRMING = 2;

export const AdminClassControl = ({clazz}) => {

    const {installation} = useMeta();
    const {setMessage, setError} = useToast();
    const {push} = useHistory();

    const [mode, setMode] = useState(MODE_SET);

    const confirmDelete = () => {
        setMode(MODE_CONFIRMING);
    }

    const cancelConfirmDelete = () => {
        setMode(MODE_SET);
    }

    const deleteClass = () => {
        deleteDocument(installation, 'classes', clazz)
            .then(() => {
                setMessage('You deleted a class.');
                setMode(MODE_SET);
            })
            .catch((e) => {
                setMode(MODE_SET);
                setError(e.message)
            });
    }

    const editClass = () => {
        push(`/${installation}/admin/classes/edit/?class=${clazz.id}`)
    }

    const controls = () => {
        switch (mode) {
            case MODE_CONFIRMING:
                return (<Row className="w-100 h-100 m-0 p-0 align-items-center justify-content-center">
                        <Col xs={12} className="m-0 pr-3 text-right">
                            <Button variant="outline-secondary" className="mr-3"
                                    onClick={cancelConfirmDelete}>Keep</Button>
                            <Button autoFocus variant="danger" onClick={deleteClass}>Delete class</Button>
                        </Col>
                    </Row>
                );
            case MODE_SET:
            default:
                return (<Row className="w-100 h-100 m-0 p-0 align-items-center justify-content-center">
                        <Col xs={12} className="m-0 pr-3 text-right">
                            <Button variant="outline-primary" className="mr-3" onClick={editClass}>Edit</Button>
                            <Button variant="outline-danger"
                                    onClick={confirmDelete}>
                                <Icon.Trash size={15}/>
                            </Button>
                        </Col>
                    </Row>
                );
        }
    }

    return (
        <React.Fragment>
            {controls()}
        </React.Fragment>
    );
}