import React, {useRef} from "react";
import {Col, Form, Row} from "react-bootstrap";

export const ClientClassesFilter = ({setFilterCriteria}) => {

    const rangeRef = useRef();
    const slotRef = useRef();
    const levelRef = useRef();
    const durationRef = useRef();

    const filterChanged = () => {
        setFilterCriteria(
            {
                range: rangeRef.current.value,
                slot: slotRef.current.value,
                level: levelRef.current.value,
                duration: durationRef.current.value,
            }
        );
    }


    return (
        <Form className="my-2">
            <Row className="pl-0 pr-1">
                <Col xs={12} sm={6} md={3}>
                    <Form.Control className="m-1" as="select" ref={rangeRef} defaultValue={'Upcoming'}
                                  onChange={filterChanged}>
                        <option value={'Upcoming'}>Upcoming</option>
                        <option value={'Past'}>Past</option>
                    </Form.Control>
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <Form.Control className="m-1" as="select" ref={slotRef} defaultValue={'Any time'}
                                  onChange={filterChanged}>
                        <option value={'Any time'}>Any time</option>
                        <option value={'Morning'}>Morning</option>
                        <option value={'Midday'}>Midday</option>
                        <option value={'Afternoon'}>Afternoon</option>
                        <option value={'Evening'}>Evening</option>
                    </Form.Control>
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <Form.Control className="m-1" as="select" ref={levelRef} defaultValue={'All levels'}
                                  onChange={filterChanged}>
                        <option value={'All levels'}>All levels</option>
                        <option value={'Beginner'}>Beginner</option>
                        <option value={'Intermediate'}>Intermediate</option>
                        <option value={'Advanced'}>Advanced</option>
                    </Form.Control>
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <Form.Control className="m-1" as="select" ref={durationRef} defaultValue={'Any duration'}
                                  onChange={filterChanged}>
                        <option value={'Any duration'}>Any duration</option>
                        <option value={'45'}>45 min</option>
                        <option value={'60'}>60 min</option>
                        <option value={'90'}>90 min</option>
                    </Form.Control>
                </Col>
            </Row>
        </Form>
    )
}