import React, {useEffect, useRef, useState} from 'react';
import {Form, Button, Alert} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import styled from "styled-components";
import {useAuth} from "../../context/auth.context";
import {Centered} from "../shared/centered";
import {APP_NAME} from "../../globals";

const EmailField = styled(Form.Control)`
  margin-bottom: 10px;
`;

export const PasswordReset = () => {

    const { id } = useParams();

    const emailRef = useRef();
    const {resetPassword} = useAuth();

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        document.title = APP_NAME + " - Password Reset";
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setError('');
            setSuccess('');
            await resetPassword(emailRef.current.value);
            setSuccess('Check your inbox!')
        } catch {
            setError('Error sending email!');
        }
    }

    return (
        <Centered>
            <h1 className="h3 mb-3 fw-normal">Password reset</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form className="mb-2" onSubmit={handleSubmit}>
                <Form.Group controlId="email" style={{margin: 0}}>
                    <EmailField ref={emailRef} type="email" placeholder="Email address" required autoFocus/>
                </Form.Group>
                <Button variant="primary" size="lg" type="submit" block>Send reset email</Button>
            </Form>
            <div><Link className="signin-alt" to={`/${id}/signin`}>Back to Sign In</Link></div>
        </Centered>
    )
}