import React, {useEffect, useState} from "react";
import {Page} from "../../shared/page";
import {AdminWorkBench} from "./adminWorkBench";
import {setTitle} from "../../../globals";
import {useMeta} from "../../../context/meta.context";
import {getUUID, useCollection} from "../../../hooks/useFirestore";
import {Button, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import {AdminClass} from "./adminClass";
import {useUserData} from "../../../context/user.context";
import * as Icon from "react-bootstrap-icons";
import {useHistory} from "react-router-dom";

export const AdminClasses = () => {

    const {installation} = useMeta();
    const {push} = useHistory();
    const {userData} = useUserData();

    const {docs} = useCollection(installation, 'classes');

    const [hidePast, setHidePast] = useState(true);

    useEffect(() => {
        setTitle('Classes');
    }, [])

    const addClass = () => {
        const id = getUUID();
        push(`/${installation}/admin/classes/edit/?class=${id}`);
    }

    const todayDate = new Date().toJSON().substr(0, 10);
    const todayTime = new Date().toJSON().substr(11, 5);

    const applyFilter = (clazz) => {
        const isInPast = clazz.date < todayDate || (clazz.date === todayDate && clazz.time < todayTime);
        if (hidePast && isInPast) {
            return false;
        }
        return true;
    }

    const filteredDocs = docs.filter(applyFilter);

    const content = () => {
        return (
            (!userData)
                ? <div>Loading ...</div>
                : <React.Fragment>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={12} sm={4} className="text-left">
                            <h3>Class plan</h3>
                        </Col>
                        <Col xs={8} sm={6} className="text-right">
                            <Form.Check checked={hidePast} type="checkbox"
                                        label="Hide classes in the past"
                                        onChange={() => setHidePast(!hidePast)}
                            />
                        </Col>
                        <Col xs={2} sm={2} className="text-right">
                            <Button variant="outline-primary" className="mr-3" onClick={addClass}>
                                <Icon.PlusSquare size={15}/>
                            </Button>
                        </Col>
                    </Row>
                    {docs &&
                    <Row className="my-3 align-items-baseline">
                        <Col xs={12} className="text-center">
                            <ListGroup>
                                {
                                    filteredDocs.map((clazz, idx) => <AdminClass key={clazz.id} clazz={clazz}/>)
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                    }
                </React.Fragment>
        )
    }

    return (
        <Page>
            <AdminWorkBench activeKey="classes">
                <Container className="border border-top-0 rounded-bottom">
                    <Row className="justify-content-center">
                        <Col xs={12} className="w-95 mb-4">
                            {content()}
                        </Col>
                    </Row>
                </Container>
            </AdminWorkBench>
        </Page>
    )

}