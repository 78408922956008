import React, {useContext, useEffect, useState} from "react";
import {firestore} from "../firebase/firebase";
import {useAuth} from "./auth.context";

const MetaContext = React.createContext();

export const useMeta = () => {
    return useContext(MetaContext);
}

export const MetaProvider = ({installation, children}) => {

    const {currentUser} = useAuth();
    const [meta, setMeta] = useState(null);

    useEffect(() => {
        if (currentUser) {
            firestore.doc(`installations/${installation}`)
                .onSnapshot((snapshot) => setMeta({
                    ...snapshot.data()
                }))
        }
    }, [installation, currentUser]);

    const value = {
        installation,
        ...meta
    }

    return (
        <MetaContext.Provider value={value}>
            {children}
        </MetaContext.Provider>
    );
}