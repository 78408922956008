import React from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";

export const ClientWorkBench = ({activeKey, children}) => {

    const { id } = useParams();

    const history = useHistory();

    const handleTabChange = (key) => {
       switch (key) {
           case 'account':
               history.push(`/${id}/client/account/`);
               return;
           default:
               history.push(`/${id}/client/classes/`);
       }
    }

    return (
        <Tabs id="client-view"
              activeKey={activeKey}
              onSelect={(k) => handleTabChange(k)}
        >
            <Tab eventKey="classes" title="Classes">
                {activeKey === 'classes' && children}
            </Tab>
            <Tab eventKey="account" title="Account">
                {activeKey === 'account' && children}
            </Tab>
        </Tabs>
    )

}
