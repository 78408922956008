import React from 'react';
import {Switch, useParams} from "react-router-dom";

import {PrivateRoute} from "./components/private.route";
import {AdminClasses} from "./components/workbench/admin/adminClasses";
import {ClientClasses} from "./components/workbench/client/clientClasses";
import {ClientAccount} from "./components/workbench/client/clientAccount";
import {ClientAccountTopUp} from "./components/workbench/client/clientAccountTopUp";
import {UserProvider} from "./context/user.context";
import {AdminClassEdit} from "./components/workbench/admin/adminClassEdit";
import {AdminRoute} from "./components/admin.route";
import {Dispatch} from "./Dispatch";
import {AdminClients} from "./components/workbench/admin/adminClients";
import {AdminClientDetail} from "./components/workbench/admin/adminClientDetail";

function Main() {

    const {id} = useParams();

    return (
        <UserProvider installation={id}>
            <Switch>
                <PrivateRoute path="/:id/dispatch/" exact component={Dispatch}/>
                <AdminRoute path="/:id/admin/classes/" exact component={AdminClasses}/>
                <AdminRoute path="/:id/admin/classes/edit/" exact component={AdminClassEdit}/>
                <AdminRoute path="/:id/admin/clients/" exact component={AdminClients}/>
                <AdminRoute path="/:id/admin/clients/detail/" exact component={AdminClientDetail}/>
                <PrivateRoute path="/:id/client/classes/" exact component={ClientClasses}/>
                <PrivateRoute path="/:id/client/account/" exact component={ClientAccount}/>
                <PrivateRoute path="/:id/client/account/topup/" exact component={ClientAccountTopUp}/>
            </Switch>
        </UserProvider>
    );
}

export default Main;
