import React from "react";
import {useMeta} from "./context/meta.context";
import {UnderMaintenance} from "./UnderMaintenance";

export const MaintenanceGuard = ({children}) => {

    const {underMaintenance, maintenanceMessage} = useMeta();

    return (
        <>
            {(underMaintenance)
                ? <UnderMaintenance message={maintenanceMessage} />
                : children
            }
        </>
    )
}