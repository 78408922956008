import React, {useState} from "react";
import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import {Logo} from "./logo";
import {Copyright} from "./copyright";
import {useAuth} from "../../context/auth.context";
import {CurrencyValue} from "./currencyValue";
import {useUserData} from "../../context/user.context";

export const Page = ({children}) => {

    const [error, setError] = useState('');
    const {signOut} = useAuth();
    const {userData} = useUserData();

    const handleLogout = async () => {
        try {
            setError('');
            await signOut();
        } catch(e) {
            console.log(e);
            setError('Error logging out!');
        }
    }

    return (!userData)
        ? <div>Loading</div>
        : <Container className="h-100">
            <Row className="justify-content-center mt-4">
                <Col className="text-left">
                    <div className="csmall">Welcome, {userData.displayName}!</div>
                    {!userData.isAdmin &&
                    <div className="csmall">
                        Credit: <CurrencyValue amount={userData.credit}/>
                    </div>
                    }
                </Col>
                <Col className="text-center">
                    <Logo/>
                </Col>
                <Col className="text-right">
                    <Button variant="light" className="mr-1" onClick={handleLogout}>Logout</Button>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={12} className="text-center w-75 mt-4">
                    {error && <Alert variant="danger">{error}</Alert>}
                    {children}
                    <Copyright/>
                </Col>
            </Row>
        </Container>
        ;
}