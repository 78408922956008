import {useState, useEffect} from 'react';
import {firestore} from '../firebase/firebase';
import {v4 as uuidv4} from "uuid";

export const getUUID = () => uuidv4();

export const useCollection = (installation, collection) => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        const unsub = firestore.collection(`installations/${installation}/${collection}`)
            .orderBy('date')
            .onSnapshot(snap => {
                let documents = [];
                snap.forEach(doc => {
                    documents.push({...doc.data(), id: doc.id});
                });
                setDocs(documents);
            });

        return () => unsub();
    }, [installation, collection]);

    return {docs};
}

export const useClients = (installation) => {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const unsub = firestore.collection(`installations/${installation}/users`)
            .where('isAdmin', '!=', true)
            .orderBy('isAdmin')
            .orderBy('displayName')
            .onSnapshot(snap => {
                let documents = [];
                snap.forEach(doc => {
                    documents.push({...doc.data()});
                });
                setClients(documents);
            });

        return () => unsub();
    }, [installation]);

    return {clients};
}

export const getDocument = (installation, collection, id) => {
    return firestore.doc(`installations/${installation}/${collection}/${id}`)
        .get()
        .then((snapshot) => snapshot.exists ? snapshot.data() : {})
}

export const getUsers = (installation, validIds) => {
    return firestore.collection(`installations/${installation}/users`)
        .where('uid', 'in', validIds)
        .orderBy('displayName')
        .get();
}

export const getClasses = (installation) => {
    return firestore.collection(`installations/${installation}/classes`)
        .orderBy('date')
        .orderBy('time')
        .get();
}

export const upsertDocument = (installation, collection, document) => {
    return firestore.runTransaction((transaction) => {
        const docRef = firestore.doc(`installations/${installation}/${collection}/${document.id}`);
        return transaction.get(docRef)
            .then((doc) => {
                if (!doc.exists) {
                    transaction.set(docRef, document);
                } else {
                    transaction.update(docRef, {...doc.data(), ...document});
                }
            });

    });
}

export const deleteDocument = (installation, collection, document) => {
    return firestore.runTransaction((transaction) => {
        const docRef = firestore.doc(`installations/${installation}/${collection}/${document.id}`);
        return transaction.get(docRef)
            .then(() => transaction.delete(docRef));
    });
}

export const topUp = async (installation, uid, amount) => {
    return firestore.runTransaction((transaction) => {
        const userRef = firestore.doc(`installations/${installation}/users/${uid}`);
        return transaction.get(userRef)
            .then((user) => {
                if (user.exists) {
                    const userData = user.data();
                    const existingCredit = (userData.credit) ? parseFloat(userData.credit) : 0.0;
                    const newCredit = existingCredit + parseFloat(amount);
                    return transaction.update(userRef, {
                        ...userData,
                        credit: newCredit
                    })
                }
            })
    });
}

export const bookClass = async (installation, collection, document, uid) => {
    return firestore.runTransaction(async (transaction) => {
        const docRef = firestore.doc(`installations/${installation}/${collection}/${document.id}`);
        const userRef = firestore.doc(`installations/${installation}/users/${uid}`);
        const classSnapShot = await transaction.get(docRef);
        const userSnapShot = await transaction.get(userRef);
        if (classSnapShot.exists && userSnapShot.exists) {
            const clazz = classSnapShot.data();
            const user = userSnapShot.data();
            const newCredit = parseFloat(user.credit) - parseFloat(clazz.price);
            if (newCredit >= 0.0) {
                const clients = (clazz.clients || []);
                const otherClients = clients.filter((c, i) => c.uid !== uid)
                await transaction.update(userRef, {
                    credit: newCredit
                });
                return transaction.update(docRef, {
                    clients: [
                        ...otherClients,
                        {
                            uid,
                            status: 'Booked'
                        }
                    ]
                })
            } else {
                throw new Error("You do not have enough credit to book this class!");
            }
        } else {
            throw new Error("Cannot find both class and client!");
        }
    });
}

export const cancelClass = async (installation, collection, document, uid) => {
    return firestore.runTransaction(async (transaction) => {
        const docRef = firestore.doc(`installations/${installation}/${collection}/${document.id}`);
        const userRef = firestore.doc(`installations/${installation}/users/${uid}`);
        const classSnapShot = await transaction.get(docRef);
        const userSnapShot = await transaction.get(userRef);
        if (classSnapShot.exists && userSnapShot.exists) {
            const clazz = classSnapShot.data();
            const user = userSnapShot.data();
            const newCredit = parseFloat(user.credit) + parseFloat(clazz.price);
            const clients = (clazz.clients || []);
            const wasBooked = clients.filter((c, i) => c.uid === uid).length === 1;
            const otherClients = clients.filter((c, i) => c.uid !== uid)
            const newClients = (wasBooked)
                ? [...otherClients, {uid, status: "Cancelled"}]
                : otherClients;
            await transaction.update(userRef, {
                credit: newCredit
            });
            return transaction.update(docRef, {
                clients: newClients
            })
        }
    });
}
