import React, {useEffect, useState} from "react";
import {Button, Col, Container, ListGroup, Row} from "react-bootstrap";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Page} from "../../shared/page";
import {AdminWorkBench} from "./adminWorkBench";
import {getClasses, getDocument} from "../../../hooks/useFirestore";
import {useToast} from "../../../context/toast.context";
import {CurrencyValue} from "../../shared/currencyValue";
import {AdminClientDetailClass} from "./adminClientDetailClass";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const AdminClientDetail = () => {

    const {id} = useParams();

    const {push} = useHistory();

    const {setError} = useToast();

    const clientId = useQuery().get('id');

    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState({});
    const [classes, setClasses] = useState([]);

    useEffect(() => {
        getDocument(id, 'users', clientId)
            .then((c) => {
                setClient(c)
                return c;
            })
            .then((c) => {
                getClasses(id)
                    .then((coll) => {
                        const filteredClasses = [];
                        coll.forEach(e => {
                            const clazz = e.data();
                            if (clazz.clients && clazz.clients.filter(x => x.uid === clientId).length > 0) {
                                filteredClasses.push(clazz)
                            }
                        })
                        setClasses(filteredClasses)
                    })
            })
            .then(() => setLoading(false))
            .catch((e) => setError(e.message))
    }, [clientId, loading, id, setError])

    const content = () => {
        return (
            <>
                <Row className="my-3 align-items-baseline">
                    <Col xs={9} className="text-left">
                        <h3> Client detail </h3>
                    </Col>
                    <Col xs={3} className="text-right">
                        <Button variant="outline-primary"
                                className="mr-4"
                                onClick={() => push(`/${id}/admin/clients/`)}
                        >Back</Button>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={4} md={2} className="text-right">
                        <h5>Name</h5>
                    </Col>
                    <Col xs={8} className="text-left">
                        <span className="fs-larger text-muted">{client.displayName}</span>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={4} md={2} className="text-right">
                        <h5>Email</h5>
                    </Col>
                    <Col xs={8} className="text-left">
                        <span className="fs-larger text-muted">{client.email}</span>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={4} md={2} className="text-right">
                        <h5>Credit</h5>
                    </Col>
                    <Col xs={8} className="text-left">
                        <span className="fs-larger text-muted"><CurrencyValue amount={client.credit}/></span>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={12} className="text-left">
                        <h3>Classes booked / cancelled</h3>
                    </Col>
                </Row>
                <Row className="my-3 align-items-baseline">
                    <Col xs={12} className="text-left">
                        {(classes && classes.length > 0)
                            ? <ListGroup className="mx-3">
                                {
                                    classes.map((c) => <AdminClientDetailClass key={c.id} clientId={clientId} clazz={c}/>)
                                }
                            </ListGroup>
                            : <h5>None</h5>
                        }
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <Page>
            <AdminWorkBench activeKey="clients">
                <Container className="border border-top-0 rounded-bottom">
                    <Row className="justify-content-center">
                        <Col xs={12} className="w-95 mb-4">
                            {loading
                                ? <div>Loading ...</div>
                                : content()
                            }
                        </Col>
                    </Row>
                </Container>
            </AdminWorkBench>
        </Page>
    )

}