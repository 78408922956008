import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Container, Form, ListGroup, Row} from "react-bootstrap";
import {useHistory, useLocation} from "react-router-dom";
import {useMeta} from "../../../context/meta.context";
import {useAuth} from "../../../context/auth.context";
import {APP_NAME} from "../../../globals";
import * as Icon from "react-bootstrap-icons";
import {Page} from "../../shared/page";
import {AdminWorkBench} from "./adminWorkBench";
import {getDocument, getUsers, upsertDocument} from "../../../hooks/useFirestore";
import {useToast} from "../../../context/toast.context";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const AdminClassEdit = () => {

    const classId = useQuery().get('class');

    const {currency} = useMeta();
    const {installation} = useAuth();

    const {setMessage, setError} = useToast();

    const [loading, setLoading] = useState(true);
    const [clazz, setClazz] = useState({});
    const [attendees, setAttendees] = useState([]);
    const [dataComplete, setDataComplete] = useState(false);

    const dateRef = useRef();
    const timeRef = useRef();
    const durationRef = useRef();
    const priceRef = useRef();
    const levelRef = useRef();
    const titleRef = useRef();
    const zoomRef = useRef();

    const history = useHistory();

    const isNew = !clazz || !clazz.date;

    const title = (isNew) ? 'Add new class details' : 'Edit class details';

    useEffect(() => {
        document.title = APP_NAME + " - " + title;
        getDocument(installation, 'classes', classId)
            .then((c) => {
                setClazz(c);
                return c;
            })
            .then((c) => {
                const clients = (c.clients) ? c.clients : [];
                const userIds = clients.map(c => c.uid);
                if (userIds && userIds.length > 0) {
                    getUsers(installation, userIds)
                        .then((coll) => {
                            const users = [];
                            coll.forEach(e =>
                                users.push(e.data())
                            )
                            setAttendees(users)
                            setLoading(false);
                        })
                } else {
                    setLoading(false);
                }
            });
    }, [installation, classId, title])


    const isSet = (value) => {
        return value && value !== '';
    }

    const formChanged = () => {
        const complete =
            isSet(dateRef.current.value) &&
            isSet(timeRef.current.value) &&
            durationRef.current.value !== '0' &&
            isSet(priceRef.current.value) &&
            levelRef.current.value !== '0' &&
            isSet(titleRef.current.value) &&
            isSet(zoomRef.current.value);
        setDataComplete(complete);
    }

    const addOrUpdate = () => {
        const newClazz = {
            id: classId,
            date: dateRef.current.value,
            time: timeRef.current.value,
            duration: durationRef.current.value,
            price: priceRef.current.value,
            level: levelRef.current.value,
            title: titleRef.current.value,
            zoom: zoomRef.current.value
        }
        upsertDocument(installation, 'classes', newClazz)
            .then(() => isNew ? setMessage('Class added OK.') : setMessage('Class updated OK.'))
            .then(() => history.push(`/${installation}/admin/classes/`))
            .catch((e) => setError(e.message));
    }

    const content = () => {
        return (
            <React.Fragment>
                <Form className="mt-4 mb-2 ml-0 mr-2">
                    <Row className="my-3 align-items-baseline">
                        <Col xs={12} className="text-left">
                            <h3> {title} </h3>
                        </Col>
                    </Row>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={2} className="text-right">
                            <h5>Date/Time</h5>
                        </Col>
                        <Col xs={5}>
                            <Form.Control ref={dateRef} defaultValue={clazz.date} type="date" placeholder="Date"
                                          required
                                          onChange={formChanged}/>
                        </Col>
                        <Col xs={5}>
                            <Form.Control ref={timeRef} defaultValue={clazz.time} type="time" placeholder="Time"
                                          required
                                          onChange={formChanged} stepping={15}/>
                        </Col>
                    </Row>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={2} className="text-right">
                            <h5>Duration</h5>
                        </Col>
                        <Col xs={5}>
                            <Form.Control as="select" ref={durationRef} defaultValue={clazz.duration} onChange={formChanged}
                                          required>
                                <option value={'0'}>-- Choose duration --</option>
                                <option value={'45'}>45 min</option>
                                <option value={'60'}>60 min</option>
                                <option value={'90'}>90 min</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={2} className="text-right">
                            <h5>Price</h5>
                        </Col>
                        <Col xs={5}>
                            <Form.Control ref={priceRef} defaultValue={clazz.price} type="number"
                                          placeholder="Enter a price"
                                          onChange={formChanged} required/>
                        </Col>
                        <Col xs={5} className="text-left">
                            <h5>{currency}</h5>
                        </Col>
                    </Row>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={2} className="text-right">
                            <h5>Level</h5>
                        </Col>
                        <Col xs={10}>
                            <Form.Control as="select" ref={levelRef} defaultValue={clazz.level} onChange={formChanged}
                                          required>
                                <option value={'0'}>-- Choose level --</option>
                                <option value={'Beginner'}>Beginner</option>
                                <option value={'Intermediate'}>Intermediate</option>
                                <option value={'Advanced'}>Advanced</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={2} className="text-right">
                            <h5>Title</h5>
                        </Col>
                        <Col xs={10}>
                            <Form.Control ref={titleRef} defaultValue={clazz.title} type="text"
                                          placeholder="Enter a title"
                                          onChange={formChanged} required/>
                        </Col>
                    </Row>
                    <Row className="my-3 align-items-baseline">
                        <Col xs={2} className="text-right">
                            <h5>Zoom</h5>
                        </Col>
                        <Col xs={10}>
                            <Form.Control ref={zoomRef} defaultValue={clazz.zoom} as="textarea" rows={3}
                                          onChange={formChanged} placeholder="Paste Zoom Link" required/>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={6} className="text-right">
                            <Button variant="secondary" onClick={() => history.goBack()}>Cancel</Button>
                        </Col>
                        <Col xs={6} className="text-left">
                            <Button
                                variant="primary"
                                onClick={() => addOrUpdate()}
                                disabled={!dataComplete}
                            >
                                {(isNew) ? 'Add' : 'Update'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {attendeeSection()}
            </React.Fragment>
        )
    };

    const status = (attendee) => {
        return clazz.clients.filter(c => c.uid === attendee.uid)[0].status;
    }

    const isBooked = (attendee) => {
        return status(attendee) === 'Booked';
    }

    const totalAttendeesBooked = () => {
        return (clazz && clazz.clients)
            ? clazz.clients.filter(c => c.status === 'Booked').length
            : 0;
    }

    const totalAttendees = () => {
        return (clazz && clazz.clients)
            ? clazz.clients.length
            : 0;
    }

    const attendeeSection = () => {
        return (
            <>
                <Row className="my-3 align-items-baseline">
                    <Col xs={12} className="text-left">
                        <h3> Attendees </h3>
                    </Col>
                </Row>
                {totalAttendees() > 0 &&
                <Row className="my-3 mx-1 align-items-baseline">
                    <Col sm={12} md={4} className="text-left">
                        <h5> Total: {totalAttendees()} </h5>
                    </Col>
                    <Col sm={12} md={4} className="text-left">
                        <h5> Booked: {totalAttendeesBooked()} </h5>
                    </Col>
                    <Col sm={12} md={4} className="text-left">
                        <h5> Cancelled: {totalAttendees() - totalAttendeesBooked()} </h5>
                    </Col>
                </Row>
                }
                <Row className="my-3 align-items-baseline">
                    <Col xs={12} className="text-left">
                        {(attendees && attendees.length > 0)
                            ? <ListGroup className="mx-2">
                                {attendees.map((client) => {
                                    return (
                                        <ListGroup.Item action key={client.uid}>
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col sm={12} md={6}>
                                                            {client.displayName}
                                                        </Col>
                                                        <Col sm={12} md={6}>
                                                            {client.email}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="text-right align-items-baseline h-100">
                                                    {(isBooked(client))
                                                        ? <Icon.CheckSquareFill size={24}/>
                                                        : <Icon.XSquare size={24}/>
                                                    }
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )
                                })
                                }
                            </ListGroup>
                            : <h5>None</h5>
                        }
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <Page>
            <AdminWorkBench activeKey="classes">
                <Container className="border border-top-0 rounded-bottom">
                    <Row className="justify-content-center">
                        <Col xs={12} className="w-95 mb-4">
                            {loading
                                ? <div>Loading ...</div>
                                : content()}
                        </Col>
                    </Row>
                </Container>
            </AdminWorkBench>
        </Page>
    )
}